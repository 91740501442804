import * as React from 'react'
import { Box } from '@mui/material'

import Header from './components/Header'
import Routes from './routes'

export default function App() {
  return (
    <>
      <Header />
      <Box sx={{ py: { xs: 12, md: 20 } }}>
        <Routes />
      </Box>
    </>
  )
}
