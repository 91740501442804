import { Link } from '@mui/material'
import React from 'react'

import { NAV_ITEMS } from '../../config/navs'

export default function NavItems({ header }) {
  const { pathname } = window.location

  return NAV_ITEMS.map((item, index) => (
    <Link
      key={index}
      href={item.path}
      underline="hover"
      sx={{
        color: pathname === item.path && header ? '#28e2e2' : '#fff',
        display: 'block',
        cursor: 'pointer',
        p: 2,
        fontWeight: pathname === item.path && header ? 600 : 500,
      }}
    >
      {item.title}
    </Link>
  ))
}
