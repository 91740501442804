import { createTheme } from '@mui/material/styles'
import ECABold from '../assets/Fonts/EuclidCircularA-Bold.woff2'
import ECALight from '../assets/Fonts/EuclidCircularA-Light.woff2'
import ECAMedium from '../assets/Fonts/EuclidCircularA-Medium.woff2'
import ECARegular from '../assets/Fonts/EuclidCircularA-Regular.woff2'
import ECASemiBold from '../assets/Fonts/EuclidCircularA-SemiBold.woff2'

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#28e2e2',
    },
    secondary: {
      main: '#1F1f1e',
    },
    error: {
      main: '#ed271d',
    },
    background: {
      default: '#000',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'EuclidCircularA';
          font-style: block;
          font-display: swap;
          font-weight: 700;
          src: local('EuclidCirculaA'), local('ECABold'), url(${ECABold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        };
        @font-face {
          font-family: 'EuclidCircularA';
          font-style: semi-bold;
          font-display: block;
          font-weight: 600;
          src: local('EuclidCirculaA'), local('ECASemiBold'), url(${ECASemiBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        };
        @font-face {
          font-family: 'EuclidCircularA';
          font-style: medium;
          font-display: block;
          font-weight: 500;
          src: local('EuclidCirculaA'), local('ECAMedium'), url(${ECAMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        };
        @font-face {
          font-family: 'EuclidCircularA';
          font-style: regular;
          font-display: block;
          font-weight: 400;
          src: local('EuclidCirculaA'), local('ECARegular'), url(${ECARegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        };
        @font-face {
          font-family: 'EuclidCircularA';
          font-style: light;
          font-display: block;
          font-weight: 300;
          src: local('EuclidCirculaA'), local('ECALight'), url(${ECALight}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        };
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        containedPrimary: {
          // background: 'linear-gradient(90deg, rgba(29,215,54,1) 0%, rgba(29,215,170,1) 50%, rgba(29,215,70,1) 100%)',
          background: '#28E2E2',
          color: '#fff',
          textTransform: 'none',
          fontWeight: 600,
        },
        sizeLarge: {
          padding: '8px 40px',
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(90deg, rgba(18,18,18,1) 72%, rgba(18,18,18,0.4) 100%)',
          borderRadius: 20,
        },
      },
    },
  },
  typography: {
    fontFamily: 'EuclidCircularA, Montserrat',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 400,
    },
  },
})
theme.typography.h1 = {
  fontSize: 80,
  [theme.breakpoints.down('md')]: {
    fontSize: 40,
  },
}
theme.typography.h2 = {
  fontSize: 56,
  [theme.breakpoints.down('md')]: {
    fontSize: 32,
  },
}
theme.typography.h3 = {
  fontSize: 40,
  [theme.breakpoints.down('md')]: {
    fontSize: 32,
  },
}
theme.typography.h4 = {
  fontSize: 32,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
}
theme.typography.h5 = {
  fontSize: 24,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
}
theme.typography.subtitle1 = {
  fontSize: 24,
  lineHeight: '40px',
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
    lineHeight: '24px',
  },
}
theme.typography.subtitle2 = {
  fontSize: 18,
  lineHeight: '30px',
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
    lineHeight: '24px',
  },
}
theme.typography.subtitle3 = {
  fontSize: '24px',
  lineHeight: '40px',
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
  },
}
theme.typography.subtitle4 = {
  fontSize: 20,
  lineHeight: '30px',
  fontWeight: 700,
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
    lineHeight: '25px',
  },
}
theme.typography.caption = {
  fontSize: 16,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}

export default theme
