import { AppBar, Container, Toolbar, Box, IconButton, Drawer, Link } from '@mui/material'
import { Menu } from '@mui/icons-material'
import React, { useState } from 'react'

import NavItems from '../NavItems'
import logoImg from '../../assets/ARC_Full.svg'
import LaunchButton from '../LaunchButton'

export default function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => setDrawerOpen(!drawerOpen)

  return (
    <AppBar sx={{ background: '#000' }}>
      <Container>
        <Toolbar sx={{ backgroundColor: '#000', justifyContent: 'space-between' }} disableGutters>
          <Link href="/">
            <img alt="logo" src={logoImg} style={{ width: '140px' }} />
          </Link>
          <Box
            sx={{
              display: { md: 'flex', xs: 'none' },
              alignItems: 'center',
              flex: 1,
              py: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <NavItems header />
            </Box>
            <LaunchButton />
          </Box>
          <IconButton
            onClick={toggleDrawer}
            sx={{
              display: { md: 'none', xs: 'flex' },
            }}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </Container>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box p={3}>
          <LaunchButton />
          <Box mt={3}>
            <NavItems />
          </Box>
        </Box>
      </Drawer>
    </AppBar>
  )
}
