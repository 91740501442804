import { Card, CardMedia, Box, Typography, Button, Container, styled } from '@mui/material'
import React from 'react'

import discordImg from '../../../assets/discord.png'
import nftMosaicImg from '../../../assets/nft_mosaic.png'

const StyledCard = styled(Card)`
  border-radius: 0px;
  border: 0px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.1;
    background-image: url(${nftMosaicImg});
    background-position: center;
    background-size: cover;
  }
`

export default function JoinCommunity() {
  return (
    <StyledCard
      sx={{
        mt: { xs: 4, md: 8 },
      }}
    >
      <Container
        sx={{
          display: 'flex',
          p: 8,
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
        }}
      >
        <Box sx={{ order: { xs: 1, md: 0 }, mr: { xs: 0, md: 4 } }}>
          <Typography variant="h3" mb={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            Join our community
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" mb={4}>
            Visit our discord channel to meet the growing community of ARC investors & users, and hear all the latest updates.
          </Typography>
          <Button sx={{ width: { xs: '100%', md: 'auto' } }} size="large" variant="contained" href="https://discord.gg/arcdefi" target="_blank">
            Join discord
          </Button>
        </Box>
        <CardMedia component="img" image={discordImg} sx={{ width: '30%', order: { xs: 0, md: 1 }, mb: { xs: 2, md: 0 } }} />
      </Container>
    </StyledCard>
  )
}
