export const NAV_ITEMS = [
  { title: 'Home', path: '/' },
  { title: 'About Us', path: '/about-us' },
  { title: 'News', path: 'https://arc-market.medium.com/' },
  { title: 'Roadmap', path: '/roadmap' },
  // { title: 'Token', path: '/token' },
  { title: 'NFT', path: '/nft' },
  // { title: 'FAQ', path: '/faq' },
]

export default NAV_ITEMS
