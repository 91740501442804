import { Container } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

import DeFiJoinCommunity from './JoinCommunity'
import NFTJoinCommunity from './JoinCommunity/nft'
import ContactUs from './ContactUs'

export default function Footer() {
  const { pathname } = useLocation()

  return (
    <>
      {pathname === '/nft' ? <NFTJoinCommunity /> : <DeFiJoinCommunity />}
      <Container>
        <ContactUs />
      </Container>
    </>
  )
}
