import { Button } from '@mui/material'
import React from 'react'

export default function LaunchButton() {
  return (
    <Button
      href="https://app.arc.market"
      target="_blank"
      variant="contained"
      size="large"
      sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', sm: 'auto' } }}
    >
      Launch dApp
    </Button>
  )
}
