import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Footer from '../components/Footer'

const routes = [
  { path: '/about-us', component: lazy(() => import('../pages/AboutUs')) },
  { path: '/roadmap', component: lazy(() => import('../pages/RoadMap')) },
  { path: '/token', component: lazy(() => import('../pages/Token')) },
  { path: '/faq', component: lazy(() => import('../pages/FAQ')) },
  { path: '/nft', component: lazy(() => import('../pages/NFT')) },
  { path: '/', component: lazy(() => import('../pages/Home')) },
]

function ComposeRoutes() {
  return (
    <Suspense fallback={<div />}>
      <BrowserRouter>
        <Routes>
          {routes.map(({ component, path }, i) => {
            const PageComponent = component
            return <Route key={i} path={path} element={<PageComponent />} />
          })}
        </Routes>
        <Footer />
      </BrowserRouter>
    </Suspense>
  )
}

export default ComposeRoutes
export { ComposeRoutes }
